import clsx from 'clsx';
import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'gatsby-plugin-intl';
import { Link } from 'gatsby';
import { createStructuredSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';
import LanguageSelect from '../language-select';
import RegionSelect from '../region-select';
import LogoImage from '../images/logoImage';
import useDropdown from '../../hooks/useDropDown';
import checkMobile from '../../hooks/checkMobile';
import { logOut } from '../../store/actions';
import { isClient, isIbiInstances } from '../../helpers/utils';
import { selectCart } from '../../store/selectors';
import { selectEntityUserDetails } from '../../store/selectors/entities';
import { pageLinks } from '../../helpers/navigation';
import { useStorage } from '../../services/storage';
import styles from './header.module.scss';
import useScroll from '../../utils/useScroll';

const navigationLinks = [
  // {
  //   id: 0,
  //   title: 'home',
  //   url: pageLinks.home,
  // },
  // {
  //   id: 1,
  //   title: 'pricing',
  //   url: pageLinks.pricing,
  //   partiallyActive: true,
  // },
  // {
  //   id: 2,
  //   title: 'membership_privileges',
  //   url: pageLinks.memberShipPrivileges,
  //   partiallyActive: true,
  // },
  // blog,
  {
    id: 4,
    title: 'Promote',
    url: pageLinks.promote,
  },
  {
    id: 5,
    title: 'About us',
    url: pageLinks.aboutUs,
  },
];

const stateSelector = createStructuredSelector({
  cartData: selectCart,
  userDetails: selectEntityUserDetails,
});

const Header = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const isMobile = checkMobile();
  const { isStickyHeader } = useScroll();

  const toggleRef = useRef(null);
  const dropMenuRef = useRef(null);
  const { activeNav, showLogOutOnly, hideNav, hideSignUpButton } = props;
  const { userDetails } = useSelector(stateSelector);
  const fullName = userDetails && userDetails.fullName;
  const firstName = userDetails && userDetails.firstName;
  const lastName = userDetails && userDetails.lastName;
  const isUserLogged = isClient && useStorage().getItem('UN');
  const menuPlacement = isMobile ? 'bottom' : 'top';

  const [isDropDownOpen, toggleDropDown] = useDropdown(dropMenuRef, toggleRef);

  const [openedMobileNavigation, setOpenedMobileNavigation] = useState(false);

  const onNavBurgerClick = () => setOpenedMobileNavigation(!openedMobileNavigation);

  const onLogOutClick = () => {
    dispatch(logOut());
  };

  const menu = intl.formatMessage({ id: 'menu' });
  const close = intl.formatMessage({ id: 'close' });
  const logOutText = intl.formatMessage({ id: 'button.logOut' });

  return (
    <header
      className={clsx(
        styles.header,
        openedMobileNavigation ? styles.is_active : {},
        isStickyHeader ? styles.headerSticky : {}
      )}
    >
      <div className={styles.header_wrapper}>
        <button className={styles.nav_burger} type="button" onClick={onNavBurgerClick}>
          <i className={clsx(openedMobileNavigation ? 'icon-close' : 'icon-menu')} />
          <span>{openedMobileNavigation ? close : menu}</span>
        </button>
        {showLogOutOnly ? (
          <div className={styles.header_first_part} />
        ) : (
          <div className={styles.header_first_part}>
            <Link to={pageLinks.home} className={styles.logo}>
              <LogoImage />
            </Link>

            <div className={styles.mobile_navigation}>
              <div />
              <div className={clsx(styles.buttons, fullName && styles.column)}>
                {!isIbiInstances && (
                  <div className={clsx(styles.countrySelect, styles.marginRight)}>
                    <RegionSelect />
                  </div>
                )}

                {/*<div className={clsx(styles.languageSelect, styles.marginRight)}>*/}
                {/*  <LanguageSelect id="headerLanguages" menuPlacement={menuPlacement} />*/}
                {/*</div>*/}

                {!hideNav && (
                  <nav className={styles.navigation}>
                    <ul>
                      {navigationLinks.map((link) => (
                        <li key={link.id} className={activeNav ? styles.active_link : null}>
                          <Link
                            to={link.url}
                            activeClassName={styles.active}
                            className={styles.navigation_link}
                            partiallyActive={link.partiallyActive}
                          >
                            <span>{link.title}</span>
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </nav>
                )}

                {!isUserLogged && !hideSignUpButton && (
                  <Link className={styles.loginButton} to={pageLinks.signUp2}>
                    Sign up
                  </Link>
                )}

                {fullName && (
                  <button
                    type="button"
                    className={clsx(styles.logOut, styles.mobileLogOut)}
                    onClick={onLogOutClick}
                  >
                    {logOutText}
                  </button>
                )}
              </div>
            </div>
          </div>
        )}

        <div className={clsx(styles.header_info, showLogOutOnly ? styles.only_log_out : {})}>
          {fullName && (
            <>
              <div className={styles.dropdownWrapper}>
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                <div
                  className={clsx(styles.header_user, isDropDownOpen && styles.header_user_opened)}
                  onClick={() => !openedMobileNavigation && toggleDropDown(!isDropDownOpen)}
                >
                  <p className={`${clsx(styles.username)} dont-translate`}>
                    {firstName}&nbsp;{lastName}
                  </p>
                  <i className="icon-arrow-down" />
                </div>

                {isDropDownOpen && (
                  <div className={styles.dropdown}>
                    <button type="button" className={styles.dropdownItem} onClick={onLogOutClick}>
                      {logOutText}
                    </button>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
      {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
      <button
        type="button"
        className={clsx(
          styles.navigation_overlay,
          openedMobileNavigation && styles.navigation_overlay_active
        )}
        onClick={onNavBurgerClick}
      />
    </header>
  );
};

Header.propTypes = {
  activeNav: PropTypes.bool,
  showLogOutOnly: PropTypes.bool,
  hideNav: PropTypes.bool,
  hideSignUpButton: PropTypes.bool,
};

Header.defaultProps = {
  activeNav: false,
  showLogOutOnly: false,
  hideNav: false,
  hideSignUpButton: false,
};

export default Header;
