import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './loader.module.scss';

const Loader = ({ isLoading, isSmall, isAttachedToParent, whiteBg }) =>
  isLoading ? (
    <div
      className={clsx(
        styles.wrapper,
        isSmall && styles.isSmall,
        isAttachedToParent && styles.isAttachedToParent,
        whiteBg && styles.whiteBg
      )}
    >
      <div className={styles.loader} />
    </div>
  ) : (
    <div />
  );

Loader.defaultProps = {
  isAttachedToParent: false,
  whiteBg: false,
};

Loader.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isSmall: PropTypes.bool.isRequired,
  isAttachedToParent: PropTypes.bool,
  whiteBg: PropTypes.bool,
};

export default Loader;
