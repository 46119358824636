import clsx from 'clsx';

import PropTypes from 'prop-types';
import React from 'react';
import Popover from '../popover';

import styles from './input.module.scss';

const Input = (props) => {
  const {
    id,
    label,
    wrapperClassName,
    labelClassName,
    type,
    inputClassName,
    value,
    placeholder,
    onChange,
    rightSideLink,
    fullwidth,
    icon,
    pattern,
    errorMessage,
    children,
    name,
    required,
    onBlur,
    onfocus,
    disabled,
    popover,
    isOptional,
  } = props;

  const phone = type === 'tel';
  const withLongError = errorMessage && errorMessage.length >= 40;

  return (
    <div className={clsx(styles.wrapper, wrapperClassName, withLongError && styles.withLongError)}>
      <div className={clsx(styles.labelRow)}>
        {label ? (
          <label className={clsx('basicLabel', labelClassName)} htmlFor={id}>
            {label}
            {required && (
              <span aria-hidden className="asterisk">
                *
              </span>
            )}
            {isOptional && <i> (Optional)</i>}
            {popover && <Popover hoverText={popover} />}
          </label>
        ) : null}

        {rightSideLink}
      </div>

      <div className={clsx(styles.inputWrapper)}>
        {children}

        <input
          id={id}
          type={type}
          className={clsx(
            styles.input,
            inputClassName,
            fullwidth ? styles.fullwidth : null,
            icon ? styles.inputWithIcon : null,
            errorMessage ? styles.errorInput : null,
            phone ? styles.inputPhone : null
          )}
          name={name}
          value={value}
          placeholder={placeholder}
          disabled={disabled}
          onChange={onChange}
          pattern={pattern}
          onBlur={onBlur}
          onFocus={onfocus || null}
        />

        {icon ? <div className={clsx(styles.iconWrapper)}>{icon}</div> : null}

        {errorMessage ? <div className={styles.errorMessage}>{errorMessage}</div> : null}
      </div>
    </div>
  );
};

Input.defaultProps = {
  label: null,
  name: '',
  type: 'text',
  placeholder: '',
  rightSideLink: null,
  fullwidth: false,
  icon: null,
  wrapperClassName: null,
  labelClassName: null,
  inputClassName: null,
  pattern: null,
  errorMessage: null,
  children: null,
  required: false,
  isOptional: false,
  onBlur: () => {},
  onfocus: () => {},
  disabled: false,
  popover: '',
};

Input.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  onfocus: PropTypes.func,
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  wrapperClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  rightSideLink: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  fullwidth: PropTypes.bool,
  icon: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  pattern: PropTypes.string,
  errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  popover: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  isOptional: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default Input;
