import React from 'react';
import PropTypes from 'prop-types';

import styles from './banner-refferal-header.module.scss';

const BannerRefferalHeader = ({ customerId, firstName, lastName, email }) => {
  const id = customerId;
  const name = `${firstName} ${lastName}`;

  return (
    <div className={styles.bannerWrapper}>
      <section className={styles.userInfo}>
        <div className={styles.userTitle}>
          <span className={styles.userInfoLabel}>Your Referrer:</span>
          <span className={styles.userName}>{name}</span>
        </div>
        <span className={styles.verticalDivider} />
        <div className={styles.userInfoSection}>
          <span className={styles.userInfoLabel}>ID:</span>
          <span className={styles.userInfoText}>{id}</span>
        </div>
      </section>
    </div>
  );
};

BannerRefferalHeader.propTypes = {
  customerId: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
};

export default BannerRefferalHeader;
